@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
html {
    height: 100%;
}

body {
    height: auto;
    min-height: 100%;
    font-family: 'Roboto', sans-serif;
}

.login-box {
    font-family: 'Roboto', sans-serif;
}

.login-page {
    background-color: #7C7C7C;
    /* background-color: #00B300; */
}

.login-box {
    font-family: 'Roboto', sans-serif;
}

